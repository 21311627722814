.error-container {
  height: calc(100vh - 100px);
  display: grid;
  place-items: center;
  width: 80%;
  max-width: 1200px;
  margin-left: 40px;
  position: relative;

  .error-wrapper-local {
    display: flex;
    flex-direction: column;
    max-width: 1000px;

    img {
      top: 2%;
      left: 0;
      position: absolute;
      z-index: -1;
      opacity: 0.4;
      background: red;
    }
  }

  .error-quick-actions {
    margin-top: 24px;
    display: flex;
    gap: 16px;

    a {
      background: var(--primary);
      text-decoration: none;
      padding: 10px 16px;
      border-radius: var(--border-radius);
      color: var(--fd-font-color-1);
      text-transform: uppercase;
    }

    .error-page-action-button{
      background: var(--primary);
      text-decoration: none;
      padding: 10px 16px;
      border-radius: var(--border-radius);
      color: var(--fd-font-color-1);
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}