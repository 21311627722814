.tc-home-wrapper {
  display: flex;
  max-width: 1200px;
  margin-top: 32px;
  gap: 24px;
  min-height: calc(100vh - 400px);

  .tc-home-item-container {
    flex: 1;

    table {
      border: 0.5px solid var(--border);
      border-radius: var(--border-radius);
      border-collapse: collapse;
      width: 100%;
      margin-top: 8px;
      box-shadow: 0 0 0 1px var(--border);

      tbody {
        padding: 8px;
      }

      td {
        border: 1px solid var(--border);
        padding: 4px 16px;
        background: #fff;
        box-sizing: border-box;
      }
    }
  }

  .tc-summary-card {
    padding: 16px;
    background: var(--secondary-background);
    box-sizing: border-box;
    height: 100%;
  }

  .tc-summary-card-link {
    border-style: dashed;
    padding: 16px;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    place-items: center;


    &:hover {

    }
  }
}
