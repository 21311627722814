.notification-single-item {
  //border-left: 4px solid red;
  margin: 8px;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .MuiBadge-anchorOriginTopLeftRectangle {
    top: 2px;
    left: 2px;
  }
}

.no-notification {

  .no-notification-text {
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    z-index: 2000;
  }

  img {
    width: 250px;
    z-index: 1000;
  }

}

.notification-tab-sticky {
  display: flex;
  padding-right: 20px;
  align-items: center;
  position: sticky;
  top: 20px;
  border-bottom: 1px solid var(--border);
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 20px;
  margin-bottom: 20px;
  --active-border-color: trasparent;
  --active-font-color: grey;

  p {
    font-weight: 600;
    margin: 0;
    padding: 8px;
    border-bottom: 2px solid var(--active-border-color);
    cursor: pointer;
    color: var(--active-font-color);
  }

  .active-tab {
    --active-border-color: var(--secondary);
    --active-font-color: var(--secondary);
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    top: 8px;
    right: 4px;
  }
}