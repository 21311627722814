.automation-container {
  width: 90%;
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
  align-items: start;

  .automation-wrapper {
    width: 100%;

    .span-header{
      font-size: 28px;
    }

    .select-buttons{
      margin-top: 20px;
    }
  }

  .automation-tasks{
    width: 100%;
    margin-top: 15px;
  }

  .anomaly-wrapper{
    margin-top: 40px;
    width: 100%;

    .table{
      height: calc(100vh - 260px);
    }
  }
}

.accord-details{
  padding-left: 20px;
}

.time-period-selector{
  display: flex;
  flex-direction: column;

}

.cost-anomaly-monitor{
  .r104f42s700-canary36{
    border-radius: 4px;
    height: calc(100vh - 400px) !important;
    min-height: 100px !important;
  }
  .detection-summary{

    .detection-summary-wrapper{
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: center;
    }

    .tag-compliance-wrapper{
      display: flex;
      justify-content: space-between;
      max-width: 950px;
      align-items: center;
    }

    .unused-resource-wrapper{
      display: flex;
      justify-content: space-between;
      max-width: 500px;
      align-items: center;
    }
    
    .title{
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
    }
    .content{
      font-size: 28px;
      margin: 0;
    }
  }

  .test-grid{
    border-radius: 4px;
    height: calc(100vh - 400px);
  }
}
