.tc-summary-container {
  max-width: 1200px;

  .t-c-summary-wrapper {
    display: flex;
    gap: 24px;
    width: 100%;

    .tc-summary-card {
      padding: 16px;
      background: var(--secondary-background);
      max-width: 300px;
      border-top: 4px solid rgb(random(255), random(255), random(255));
      flex: 1;
    }
  }

  .tc-summary-wrapper {
    display: flex;
    gap: 24px;

    .tc-summary-card {
      padding: 4px 16px;
      display: flex;
      background: var(--secondary-background);
      align-items: center;
      gap: 8px;
      border: 1px solid var(--border);
      border-radius: var(--border-radius);
      flex: 1;

      .tc-summary-card-graph {
        margin-top: -8px;
        margin-bottom: -12px;
        margin-left: -8px;
      }

      .tc-summary-card-content {

      }
    }

    .tc-bold {
      font-weight: 600;
    }
  }

  .tc-useful-link {
    width: 180px;

    ol {
      padding-left: 16px;

      li{
        padding: 4px 0;
      }

      a {
        color: blue;
      }
    }
  }
}
