.report-container {
  width: 90%;
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .report-wrapper {
    width: 100%;

    .span-header {
      font-size: 28px;
    }

    .select-buttons {
      margin-top: 15px;
    }
  }


  .main-header {
    height: 100%;

    .showback-search {
      padding-top: 20px;
    }

    .showback-report-data {
      height: calc(100vh - 329px);
      overflow: auto;
      padding-right: 10px;
    }
  }

  .report-outputs {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style: none;
    display: inline-flex;

    li a {
      margin-right: 10px;
      background: var(--primary);
      color: black;
      font-weight: 500;
      font-size: 15px;
      border-radius: 4px;
      padding: 10px 20px;
      text-decoration: none;
    }
  }

  .active {
    background: black;
    color: white !important;
    border: none;
  }
}


.pdf-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
}

.tag-report-wrapper {
  margin-top: 20px;

  .tag-report-filter {
    max-width: 700px;
    display: flex;
    gap: 20px
  }
}

.report-loading {
  filter: blur(1px);
  pointer-events: none;
  transition: 0.3s;
}

.report-card-main {
  max-width: 300px;
  min-width: 300px;
  transition: 0.3s;

  &:hover {
    box-shadow: 1px 2px 9px 0 #0000001f;
    transition: 0.3s;
  }


  .action-button {
    bottom: 0;
    position: absolute;
    width: 100%;
    //border-top: 1px solid var(--border);
  }
}

.report-type-cur {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--border);
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 9px 0 #0000001f;
    transition: 0.3s;
  }
}

.report-type-cur-service {
  transition: 0.3s;
  background: #4f4cff1f;
  border: 1px solid #4542ff70;

}

.report-type-cur-custom {
  transition: 0.3s;
  background: rgba(255, 171, 69, 0.15);
  border: 1px solid #ffab45;
}

.report-type-cur-cost {
  transition: 0.3s;
  background: rgba(255, 103, 103, 0.24);
  border: 1px solid #ff6767;
}

.report-type-cur-tag {
  transition: 0.3s;
  background: rgba(103, 255, 171, 0.16);
  border: 1px solid #67ffab;
}