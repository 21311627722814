.p-11 {
  padding: 11px;
}


.environment-account-wrapper {
  border-bottom: 1px solid var(--border);
  padding: 10px 0 20px;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}

.left-style {
  display: flex;
  justify-content: flex-start;
  transition: 0.3s;
  align-items: center;
  gap: 10px;
}


.right-style {
  display: flex;
  justify-content: flex-end !important;
  transition: 0.3s;
  align-items: center;
  gap: 20px;
}

.ob-download-button {
  position: absolute;
  //background-color: #666666 !important;
  bottom: 40px;
  color: white;
  right: 100px;
  border-radius: 25px !important;
  padding: 16px;
  cursor: pointer;
  align-items: center;
  transition: 0.3s;

  /*  &:hover {
      height: 300px;
      transition: 0.3s;
    }*/
}