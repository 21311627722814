.date-range-picker {
  font-family: var(--font-familly);
  border-radius: var(--border-radius);
}

input, select {
  font-family: var(--font-familly);
}

.custom-date-calender {
  align-items: start;
  background-color: #eff2f7;
  border-radius: var(--border-radius);
  border: 1px solid var(--border);
  //box-shadow: 0 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .icon-button {
    margin: 8px;
  }
}

.filer-input-box {
  position: relative;

  .filter-icon {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 12px;
    background-color: white;
  }
}

.filter-single-item {
  --border-color: var(--border);
  background-color: #ececec;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: 8px 8px 8px 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    --border-color: black;
  }
}

.report-action-custom {
  margin-left: -40px;
  margin-right: -65px;

  .report-action-wrapper{
    padding-left: 40px;
    padding-bottom: 15px;
  }
}