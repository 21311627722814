$color1: black;

.main_header {
  width: 100%;
  margin-top: 20px;
}

.d-f-j-c{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.points {
  display: inline-block;
  background-color: var(--active-primary);
  color: #FFF;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  border-radius: 40px;
  margin: 0 10px;
}

.heading {
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  transition: 1s;
  min-height: 60px;
  font-size: 16px;
  color: #000;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 8px;
}

.custom-report {
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;

  .query {
    margin: 20px 0;
    padding: 5px 10px;
    width: 89%;
  }

  .options-wrapper {
    margin: 0 0;
    display: flex;
    align-items: center;

    .item-wrapper {
      width: 70%;
      font-family: var(--font-family);
      font-weight: 500;

      button {
        outline: none;
        border: none;
        color: rgba(0, 0, 0, 0.87);
        background-color: var(--primary);
        /*box-shadow: 0px 3px 1px -2px #00000038,
                    0px 2px 2px 0px #00000014,
                    0px 1px 5px 0px #0000001f;*/
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: var(--font-family);
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        margin: 0 10px;
      }


      .ruleGroup-header {
        display: flex;
        align-items: center;
      }

      .ruleGroup-combinators {
        outline: none;
        min-height: 34px;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid rgba(0, 0, 0, 0.22);
        border-radius: 4px;
        padding: 5px;
        font-weight: 500;
        margin-right: 10px;

        &:after {
          position: absolute;
          content: "";
          top: 14px;
          right: 10px;
          width: 0;
          height: 0;
        }
      }

      .rule {
        display: flex;
        align-items: center;
        padding: 20px 0 0 20px;
      }

      .ruleGroup {
        padding: 20px 0;
      }

      .rule-fields,
      .rule-operators,
      .rule-value {
        outline: none;
        min-height: 34px;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid rgba(0, 0, 0, 0.22);
        border-radius: 4px;
        padding: 5px;
        font-weight: 500;
        margin: 0 10px 0 0;
      }

      .rule-value {
        max-height: 24px;
        min-height: 24px;
      }

      .rule-remove,
      .ruleGroup-remove {
        max-height: 30px;
        min-height: 30px;
        min-width: 30px;
        max-width: 30px;
        border-radius: 50%;
        padding: 0;
      }
    }

  }
}
