.budget-wrapper {
  margin-left: 40px;
  max-width: 92%;

  .summary-wrapper {
    padding: 32px 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .title {
    font-size: var(--fnt-subtitle-clr1);
    font-weight: var(--fnt-title-weignt);
  }

  .subtitle {

  }


  .table-container {
    height: calc(100vh - 410px);
  }

}

.budget-summary-wrapper {
  display: flex;
  padding: 20px;
  gap: 50px;

  .item {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.67);

    p {
      margin: 10px 0 0;
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.graph-wrapper {
  display: flex;
  gap: 20px
}

.budget-summary {
  position: absolute;
  bottom: 9px;
  text-align: start;
  left: 19px;
  font-weight: 500;

  p {
    margin: 5px 0;
  }
}