.auto-tagging-wrapper {
  display: flex;
  gap: 24px;
  max-width: 1200px;

  > div {
    flex: 1;
  }

  .common-wrapper-class {
    padding: 16px 24px;
    height: fit-content;
    min-width: 400px;
    //max-width: 400px;

  }

  .search-nd-tag {
    display: grid;
    gap: 8px;
  }

  .at-advanced-options {
    background: var(--fd-background-2);
    border-radius: var(--border-radius);
    padding: 16px 16px 16px;
    max-height: calc(100vh - 560px);
    overflow: auto;
  }

  .at-selected-resources {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .at-grid {
    display: grid;
    gap: 16px;
  }

  .at-filter-container {
    gap: 1px;
  }

  .Mui-selected {
    background-color: var(--secondary) !important;
    color: var(--fd-font-color-10) !important;
  }

  .at-combinator-wrapper {
    margin-bottom: 4px;
    padding-top: 8px;

  }

  .at-filter-wrapper {
    grid-template-columns: 1fr 1fr 32px;
    place-items: center;
  }

  .at-selected-resource {
    border-radius: var(--border-radius);
  }

  .at-selected-delete {
    display: grid;
    place-items: center;
    background: var(--fd-background-4);
    color: var(--fd-background-1);
    margin-right: 8px;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background: var(--fd-background-5);
    }
  }
}


.at-tag-value-preview {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.at-ct-loading {
  background-color: rgba(255, 0, 0, 0.3);
  padding: 4px 8px;
  border-radius: var(--border-radius);
  display: flex;
  gap: 8px;
  align-items: center;
}

.at-additional-tag-option {
  background: var(--fd-background-2);
  border-radius: var(--border-radius);
  padding: 16px 16px 16px;
  margin-top: 16px;

  .at-ato-single {
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    gap: 16px;
    place-items: center;
  }
}

// upload section
.at-upload-tag {
  .at-upload-dropzone {
    padding: 16px;
    border-radius: var(--border-radius);
    border: 2px dashed var(--border);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      border-color: #0f84ff;
      box-shadow: 0 0 5px 1px rgba(15, 132, 255, 0.3);
    }
  }

  .at-upload-file-list-wrapper {
    margin-top: 16px;
  }

  .at-upload-wrapper {
    background: var(--fd-background-2);
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid var(--border);
  }

  .at-upload-file-item {
    padding: 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .at-upload-file-item-status {
    margin-top: 14px;
  }

  .at-drag-active {
    border-color: #0f84ff;
    box-shadow: 0 0 5px 1px rgba(15, 132, 255, 0.3);
  }
}

.at-invalid-resource {
  background-color: #f8d8d8 !important;
}

.ag-cell-not-inline-editing {
  cursor: pointer;
}

.at-tagging-batch-status{
  display: flex;
  gap: 8px;
  align-items: center;

  .at-confirm-tagging-title-loading {
    background: rgba(15, 132, 255, 0.3);
    padding: 2px 8px;
    border-radius: var(--border-radius);
  }

  .at-failed-batches{
    background: rgba(255, 15, 15, 0.3);
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
  }
}
