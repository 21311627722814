.vpc-only {
  background-color: #ffd261 !important;
}

.default-vpc {
  background-color: #617fff !important;

  .ag-cell-value {
    color: white;
  }
}

.critical-subnet {
  background-color: #ff7e61 !important;

  .ag-cell-value {
    color: white;
  }
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: translateX(-5rem) translateY(5rem);

  ul {
    padding: 0;
    padding-left: 24px;
  }
}

.no-subnet-card {
  left: 0;
  z-index: 20000;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background-color: #ffd261;
}

.default-vpc-card {
  left: 0;
  z-index: 20000;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background-color: #617fff;
}

.table-syc-status {
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: var(--secondary-highlight);
}