header {
  height: 70px;
  width: 100%;
  //box-shadow: 0 0 5px 1px var(--border);
  border-bottom: 1px solid var(--border);
  flex-direction: row;
  align-items: center;
  align-content: center;
  display: flex;
  position: fixed;
  background: white;
  z-index: 100;
  top: 0;

  .header-container {
    width: 95.5%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-between;

    .header-left {
      flex-direction: row;
      align-items: center;
      align-content: center;
      display: flex;
      gap: 20px;

      .finops-title {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        color: #5b5b5b;
      }

      .inner-wrapper {
        margin: 0 0 0 20px;
      }
    }
  }
}


.organization-help-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000059;
  /* filter: blur(1px); */
  backdrop-filter: blur(10px);
  z-index: 1500;

  .w-100 {
    width: 100%;
  }

  .dialog {
    background: var(--light);
    width: 400px;
    height: 60%;
    border-radius: var(--border-radius);
    padding: 20px;

    img{
      width: 552px;
      transform: translateX(-70px);
    }
  }
}

.d-f-c {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}