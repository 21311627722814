.about-wrapper {
  max-width: 1200px;
  margin-left: 32px;
  ul {
    padding-top: 5px;

    li {
      padding: 5px 0;
    }
  }
}

