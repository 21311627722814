.rdrDay {
  --calender-text: black;
}

.calender-new-resources {

  position: relative;
  color: var(--calender-text);
  z-index: 100;

  .bubble {
    --provisioned-intensity: green;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 8px;
    background: var(--provisioned-intensity);
    border-radius: 20px;
    height: 8px;
    width: 8px;
  }

  .bubble-low-intensity {
    --provisioned-intensity: #ffa704;
  }

  .bubble-medium-intensity {
    --provisioned-intensity: #ff5c04;
  }

  .bubble-high-intensity {
    --provisioned-intensity: #ff0404;
  }
}

.rdrDayDisabled, .rdrDayPassive {
  --calender-text: #9b9b9b;
}
.rdrDayHovered,
.rdrStartEdge + .rdrDayNumber,
.rdrInRange + .rdrDayNumber,
.rdrEndEdge + .rdrDayNumber {
  --calender-text: #ffffff;
}