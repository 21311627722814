.wu-information-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  min-height: 100px;
  z-index: 1000;
  overflow: hidden;

  .information-wrapper {
    position: relative;
    padding: 18px 16px 16px;

    .close-icon {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    ul{
      margin: 0;
      padding: 16px;
      li{
        padding: 0 8px 8px;
      }
    }
  }

  //box-shadow: 1px 1px 2px 1px var(--border);
}