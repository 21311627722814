@import url('https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap');

.resource-tag-selector {
  height: 100vh;
  width: 100vw;
  display: flex;
  box-sizing: border-box;
  scroll-behavior: smooth;

  > * {
    box-sizing: border-box;
  }

  .resource-tag-selector-wrapper {
    height: 100%;
    width: 400px;
    min-width: 400px;
    gap: 16px;
    border-right: 1px solid #d7d7d7;
  }

  .rt-organization-title {
    padding: 16px 16px;
    background: black;

    img {
      width: 100%;
      max-width: 200px;
    }
  }

  .rt-organization-list {
    padding: 8px 16px;
  }

  .rt-application-wrapper {
    overflow: auto;
    margin-top: 12px;
    border-top: 1px solid #d7d7d7;
    max-height: calc(100vh - 186px);
    position: relative;

    .rt-input {
      position: sticky;
      top: 0;
      background: #fff;
      padding: 12px 16px 8px;
    }

    input {
      box-sizing: border-box;
      padding: 16px;
      width: 100%;
      outline: none;
      border: none;
      background: #f5f5f5;
      position: sticky;
      border-radius: 4px;

      &:focus, :focus-within {
        background: white;
        box-shadow: 0 4px 5px 0 #c9c9c9;
      }
    }
  }

  .rt-application {
    padding: 8px 18px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }

  .rt-active {
    background: #f1f1f1;
  }

  .rt-application-container {
    max-height: 100vh;
    overflow: auto;
    width: calc(100vw - 400px);
    box-sizing: border-box;
  }

  .rt-application-preview {
    margin: 16px 0 16px 24px;
    max-width: 1100px;
    width: calc(100vw - 500px);
    padding-right: 20px;
    padding-left: 8px;
    box-sizing: border-box;

    .rt-content {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      margin-top: 24px;

      .__json-pretty__ {
        padding: 0;
        font-size: 12px;
        margin: 0;
        line-height: 18px;
        font-family: 'Fira Mono', monospace;
      }

      .__json-key__ {
        color: rgb(255, 89, 89)
      }


      .__json-value__ {
        color: rgb(255, 0, 0);
        font-weight: 600;
        text-decoration: line-through;
      }


      .__json-string__ {
        color: #00ae1b
      }


      .__json-boolean__ {
        color: rgba(102, 153, 204, 1)
      }


      .__json-pretty-error__ {
        line-height: 1.3;
        color: rgba(248, 248, 242, 1);
        background: #1e1e1e;
        overflow: auto;
      }

    }

    code {
      line-height: 18px;
      font-size: 12px;
      font-family: 'Fira Mono', monospace;
    }


    .rt-application-data {
      transition: 0.3s;
      flex: 1;
      text-size-adjust: auto;
      display: flex;
      gap: 16px;
      flex-direction: column;
    }

    .rt-raw-data {
      flex: 0;
      flex-basis: 400px;
      max-width: 100%;
      height: fit-content;
    }

    @media (max-width: 800px) {
      .rt-raw-data {
        flex: 1;
      }
    }

    .rt-use-full-links {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 12px;
      flex: 1;
    }

    .rt-application-option {
      padding: 4px 16px 4px 8px;
    }
  }

  .rt-content-copy {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  ol {
    padding: 0 16px;
    margin-top: 8px;

    li {
      padding: 2px 0;
    }
  }

  .rt-background-color{
    background: rgba(248, 248, 248, 0.48);
  }
}