.tc-tag-issues-wrapper {
  margin-top: 3rem;
  max-width: 80%;

  .tc-summary-card {
    background: var(--secondary-background);
    padding: 16px;
    border-top: 4px solid rgb(random(255), random(255), random(255));
    transition: 0.3s;
    /*
        &:hover{
          transition: 0.3s;
          transform: scale(1.05);
          z-index: 1000;
        }*/
  }

  .tc-most-error {
    min-width: 300px;
  }

  .tc-tag-issues-summary {
    margin-top: 1rem;
    display: flex;
    gap: 24px;
    align-items: stretch;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    table {
      border: 1px solid var(--border);
      border-radius: var(--border-radius);
      border-collapse: collapse;

      td {
        border: 1px solid var(--border);
        padding: 4px 16px;
        background: #fff;
      }
    }
  }
}


/**** details ***/


.tc-details-wrapper {
  width: 100%;

  .tc-details-wrapper-bread-crumb {
    border-bottom: 1px solid var(--border);
    padding: 8px 32px;


    a {
      color: blue;
    }
  }

  .tc-details-container {
    padding: 24px 16px 16px 32px;
    max-width: 1200px;
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.show {
  opacity: 1;
}

.details-organization-wise-wrapper-container {
  position: relative;
  width: 100%;
}

.details-organization-wise-wrapper {
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  transition: 0.3s;
}

.details-org-container {
  display: flex;
  align-items: center;
  padding: 16px;
}

.tc-account-wise {
  position: absolute;
  top: 0;
  width: 100%;
  //background: ;

  .tc-account-wise-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tc-account-wise-resource-c {
  margin-top: 24px;
}

.tc-resource-list-container {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  transition: 0.2s;

  &:hover {
    background: #ececec;
    transition: 0.2s;
  }

  .tc-resource-list-inner {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-basis: 80px;
    color: grey;
  }

  .tc-tag-action {
    flex: 1;
    margin-left: auto;
    display: flex;
    justify-content: end;
    margin-right: 16px;
  }
}


.tc-resource-tag {
  margin: 0 16px 0 24px;
  border-left: 2px solid var(--border);
  padding: 8px 8px 8px 24px;
  background: var(--secondary-background);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);

  .tc-tag-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-radius: 30px var(--border-radius) var(--border-radius) 30px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      box-shadow: 1px 1px 3px 1px var(--border);
    }

    .tc-tag-icon {
      border: 1px solid var(--border);
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: grid;
      place-items: center;
    }

    .tc-item-fixable {
      box-shadow: 0 0 1px 2px #009f00,
      inset 1px 0 1px 1px red;
    }

    .tc-item-non-fixable {
      box-shadow: 0 0 1px 2px red,
      inset 1px 0 1px 1px #009f00;
    }

    .tc-tag-item-action {
      flex: 1;
      margin-left: auto;
      display: flex;
      justify-content: end;
    }
  }
}

.no-select {
  user-select: none;
}


/******/


.summary-wid-wrapper {
  background: #f1f1f1;
  cursor: pointer;
  //border-radius: var(--border-radius);
  border: 1px solid var(--border);

  &:hover {
    background: #e5e5e5;
  }
}

.summary-wid-title {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #f8f8f8;
  position: sticky;
  top: 0;
}