.tc-tag-issues-wrapper {
  margin-top: 3rem;
  max-width: 80%;


  .tc-summary-card {
    background: var(--secondary-background);
    padding: 16px;
    border-top: 4px solid rgb(random(255), random(255), random(255));
    transition: 0.3s;
  }

  .tc-most-error {
    min-width: 300px;
  }

  .tc-tag-issues-summary {
    margin-top: 1rem;
    display: flex;
    gap: 24px;
    align-items: stretch;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    table {
      border: 1px solid var(--border);
      border-radius: var(--border-radius);
      border-collapse: collapse;

      td {
        border: 1px solid var(--border);
        padding: 4px 16px;
        background: #fff;
      }
    }
  }
}

.tc-details-container-no-tag{
  width: 100%;
  padding: 0 32px;
  max-height: calc(100vh - 170px);
  overflow: auto;
  margin-top: 34px;
  box-sizing: border-box;
}

.tc-org-wise{
  position: relative;
  max-width: 1200px;

  .tc-summary-title{
    display: flex;
    background: var(--secondary-background);
    padding: 6px 16px 6px 16px;
    padding-right: 50px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}