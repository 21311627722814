.setting-wrapper {
  margin: 25px;
  --setting-color: #5e5e5e;
  --setting-subheading: #949494;
  color: var(--setting-color);

  .sub-heading {
    color: var(--setting-subheading);
  }

  .d-f-s {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
  }

  .d-f {
    display: flex;
  }

  .j-c {
    justify-content: center;
  }

  .j-s {
    justify-content: start;
  }

  .a-c {
    align-items: center;
    align-content: center;
  }

  .a-c-s {
    align-content: start;
    align-items: start;
  }

  .divider {
    border-left: 1px solid var(--border);
  }

  .setting-container {
    justify-content: start;
    align-content: start;
    align-items: self-start;
    margin-top: 20px;
  }

  .drawer {
    height: calc(100vh - 200px);
    min-width: 230px;

    nav {
      margin-top: 5px;
      margin-bottom: 10px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          a {
            color: var(--setting-color);
            text-decoration: none;
            padding: 10px 0;
            display: flex;
            align-items: center;
          }
        }
      }

      .active {
        background: none;
        font-weight: 900;
      }
    }
  }

  .p-20 {
    padding: 20px;
  }


  --settings-width: 60%;

  .resource-management-container {
    --settings-width: calc(100% - 340px) !important;
  }

  .setting-navigation {
    width: var(--settings-width);



    .region-table-title {
      padding: 10px 16px 20px 2px;
    }
  }

  .settings-title {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border);
  }

  .account-setting {
    nav {
      ul {
        list-style: none;
        display: flex;
        gap: 20px;
        margin: 0;
        padding: 0;

        li {
          a {
            color: var(--setting-color);
            display: flex;
            padding: 10px 20px;
            align-items: center;
            text-decoration: none;
            border: 1px solid var(--border);
            border-radius: var(--border-radius);
          }
        }
      }
    }

    .account-setting-list {
      margin-top: 20px;
    }
  }

}


.card-wrapper {
  border: 1px solid var(--border);
  border-left: 20px solid black;
  width: 150px;
  height: 150px;
}

.setting-profile {
  .cover-photo {
    width: 100%;
    height: 130px;
    position: relative;
    background-size: cover;
    border-radius: var(--border-radius);
  }

  .profile-photo {
    height: 150px;
    position: absolute;
    width: 150px;
    top: 50px;
    left: 20px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .profile-content {
    top: 140px;
    position: absolute;
    width: 100%;

    .profile-title {
      padding-left: 200px;
    }
  }

  .profile-actions {
    margin-top: 30px;
    padding: 20px;
  }
}