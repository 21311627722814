.error-wrapper {
  z-index: 1500;
  width: -webkit-fill-available;
  width: -moz-available;
  height: -webkit-fill-available;
  height: -moz-available;
  position: fixed;
  background: var(--light);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -100px;

  .error-container-warning{
    max-width: 1000px;
    text-align: left;
  }

  .icon {
    height: 150px;
    width: 150px;
  }
}