@import '../../../assets/variables';

.main-margin {
  margin-left: 45px;
  margin-top: 30px;
  margin-bottom: 40px;
  display: block;
}

.tab-header {
  //border-bottom: 1px solid $base_border_color;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 1000;

  .header-wrapper {
    justify-content: space-between;
    //width: 90%;
    align-items: center;

    /*nav {
      margin-top: 10px;

      ul {
        display: flex;
        list-style: none;

        li {

          padding: 0 40px 0 5px;

          a {
            color: #000000 !important;
            padding: 15px 0;
            text-decoration: none;
            font-weight: 500;
          }


          .active {
            color: #d69f00 !important;
            padding: 11px 0;
            text-decoration: none;
            font-weight: 500;
            border-bottom: 4px solid #d69f00;
            background: rgba(0, 0, 0, 0);
            transition: 0.3s;
            border-left: 0px solid #d69f00;
            padding-left: 0px !important;
          }
        }
      }
    }*/
  }

}

.savings-plan {
  display: flex;
  width: 95%;
  justify-content: space-between;

  .content {
    padding-left: 47px;
    width: 90%;
    padding-right: 20px;
    overflow: auto;
    margin-top: 40px;

    .result-item-recommendation {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      max-width: 95%;

      article {
        text-align: center;


        .cov-percentage {
          font-size: 24px;
          font-weight: 600;
          border-bottom: 1px solid $base_border_color;
          padding-bottom: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .savings-navigation {
    padding-top: 8px;
    padding-bottom: 8px;
    //border-left: 1px solid $base_border_color;
    border: 1px solid $base_border_color;
    border-radius: 5px;
    height: fit-content;
    background: #00000003;
    margin: 40px 0;
    //height: calc( 100vh - 228px);
    position: -webkit-sticky;
    position: sticky;
    top: 160px;;
    width: 20%;
    max-width: 180px;

    ul {
      list-style: none;
      padding: 0;

      li {

        a {
          display: block;
          text-decoration: none;
          padding: 1rem 1rem;
          color: $drawer_font_color;
          font-weight: var(--fnt-wt-drawer-item);
          margin: 0 10px;
        }

        .active {
          color: var(--primary) !important;
        }
      }
    }

  }
}

.date-range-action {
  position: absolute;
  margin: -35px 0 0 30px;
  font-weight: 500;
  font-size: 16px;
  display: flex;

  p {
    margin: 0 20px 0 0;
    color: #2626ff;
    cursor: pointer;
  }
}

.savings-plan-4 {
  .savingsplan-recommendations-summary {
    font-size: 20px;

  }

  .savingsplan-recommendations-wrapper {
    border: 1px solid var(--border);
    border-radius: 2px;

    .recommendations-grid {
      height: calc(100vh - 270px);
    }
  }

  .r104f42s700-canary36 {
    border-radius: 4px;
    height: 202px !important;
    min-height: 100px !important;
  }
}

.selection-items {
  font-size: 14px;
  font-weight: 500;
}

.grid-adjustments {
  height: 202px !important;
  border-radius: 4px !important;
}

.reservation-recommendation-card {
  margin: 10px 0;

  .r-r-c-head {
    display: flex;
    padding: 0 23px;
    justify-content: space-between;
    max-width: 400px;
    font-weight: 600;

    p {
      margin: 0;
      margin-top: 20px;
    }
  }

  .reservation-recommendation-item {
    display: flex;
    justify-content: space-between;

    .reservation-card-item {
      padding: 5px 10px;
      width: -webkit-fill-available;
      width: -moz-available;

      .title {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        padding: 10px 0;
        border-bottom: 1px solid $base_border_color;
      }

      .value {
        margin: 10px 0;
      }
    }
  }
}


