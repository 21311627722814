.sidedrawer-class {
  box-shadow: 0 0 5px 1px var(--border);
  position: sticky;
  top: 0;
  height: calc(100vh - 89px);
  margin-top: -70px;
  padding: 70px 16px 16px;
  transition: 0.3s;

  .bottom-menu{
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: 0 16px;
  }

  .sidedrawer-collapse {
    position: absolute;
    content: " ";
    top: 134px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    right: -12px;
    background: var(--primary);
  }


  .sidedrawer-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .navigation-no-submenu {
    white-space: nowrap;
    color: #575757;
    display: block;
    font-weight: 600;
    padding: 10px 8px;
    margin: 2px 0;
    transform: translateX(-2px);
    border-left: 2px solid transparent;
    transition: 0.3s;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: rgba(197, 197, 197, 0.13);
    }
  }

  ul {
    padding: 0;
    list-style: none;
    max-height: 54vh;
    overflow: auto;
    overflow-x: hidden;

    li {
      ul {
        height: auto;
        border-left: 2px solid var(--border);
        margin-left: 16px;
        overflow: hidden;
        transition: 2s ease;

        li {
          position: relative;
          margin-left: 8px;
        }
      }


      a {
        position: relative;
        text-decoration: none;
        color: #575757;
        display: block;
        font-weight: 600;
        padding: 10px 8px;
        margin: 2px 0;
        transform: translateX(-2px);
        border-left: 2px solid transparent;
        transition: 0.3s;
        border-radius: 8px;

        &:hover {
          //border-left: 2px solid blue;
          background: rgba(197, 197, 197, 0.13);
        }
      }
    }
  }
}

.search-collapsed {
  box-shadow: 0 0 7px 2px var(--border);
  border-radius: 30px;
  height: 40px;
  width: 40px;
  color: #1ea433;

  .search-icon {
    left: 21px !important;
  }
}

.search-wrapper {
  position: relative;
  transition: 0.3s;
  margin-bottom: 8px;

  .menu-search {
    height: 40px;
    width: 100%;
    background: #efefef;
    outline: none;
    border: none;
    padding: 16px 16px 16px 40px;
    transition: 0.3s;
    border-radius: var(--border-radius);

    &:focus {
      background: var(--light);
      box-shadow: 0 1px 3px 1px var(--border);
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%);
  }
}


.side-expand {
  width: 250px;
}

.side-collapse {
  width: 40px;
}