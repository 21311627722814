.ecs-accordion-container {
  max-height: calc(100vh - 200px);
  overflow: auto;
  position: relative;
  padding-right: 24px;

  .ecs-content-wrapper {
    border-top: 1px solid var(--border);
  }

  --launch-type-color: rgba(117, 177, 255, 0.07);
  --launch-type-border-color: #5385ff;

  .container-launch-type {
    background: var(--launch-type-color);
    padding: 0 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 4px;
    color: var(--launch-type-border-color);
    border: 1px solid var(--launch-type-border-color);
  }

  .ec2-launch-type {
    --launch-type-color: rgba(251, 146, 81, 0.07);
    --launch-type-border-color: #ff8300;
  }
}

.ecs-container {
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  width: 100%;

  /*  > * {
      flex-grow: 1;
    }*/

  .ecs-main-container {
    flex-grow: 1;
    padding: 16px 0 16px 24px;
    height: calc(100vh - 102px);
  }


  a {
    color: blue;
  }
}

.ecs-wrapper {
  box-sizing: border-box;
  margin-top: 40px;
  margin-left: 40px;

  > * {
    box-sizing: border-box;
  }

}

.summary-block {
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
  border-left: 1px solid var(--border);
  flex-basis: 250px;

  .summary-block-inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 250px;
  }
}

// task
.container-summary-card-additional-info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/// container summary card
.container-summary-card-task {
  display: flex;
  gap: 16px;

  > * {
    flex-basis: 50%;
  }
}

/// accordion

.ecs-accordion-title {
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 66px;
  z-index: 100;
  background: var(--secondary-background);

  .ecs-accordion-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}

.ecs-home-container {
  width: 100%;

  .tab-container {
    --border-color: trasparent;
    display: flex;
    align-items: center;
    gap: 16px;


    .active-ecs-filter {
      width: auto;
      max-width: 40%;
      padding: 0 4px 0 16px;
      gap: 16px;
      background: var(--secondary-background);
      border: 1px solid var(--border);
      border-radius: 4px;
      display: flex;
      align-items: center;

      > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .tab-container-wrapper {
      display: flex;
      cursor: pointer;
    }

    .tab-item {
      position: relative;
      padding: 12px 16px 14px;
      display: inline-block;

      .tab-inline-count-container{

        display: inline-block;
        .tab-inline-count{
          border-radius: 10px;
          padding: 2px 4px;
          min-width: 16px;
          font-size: 12px;
          background: var(--secondary);
          color: #fff;
          display: grid;
          place-items: center;
          margin-left: 8px;
          line-height: -2px;
        }
      }

      &::after {
        position: absolute;
        bottom: 0;
        height: 3px;
        left: 0;
        background: var(--border-color);
        content: "";
        right: 0;
        transition: 0.2s;
      }
    }

    .active-tab {
      --border-color: black;
    }
  }
}


.ecs-details-container {
  position: relative;
  max-height: 100vh;
  overflow: hidden;

  .ecs-dialog-action-bar {
    position: sticky;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    padding-left: 24px;
    top: 0;
    background: #fff;
    max-height: 80px;
  }

  .ecs-dialog-title {
    padding: 8px 0;
  }

  .ecs-additional-information {
    //padding: 24px 32px 24px 24px;
  }

  .cluster-overall-info {
    align-items: stretch;

    & > * {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .container-summary-card {
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    padding: 16px;
    border-top: 4px solid var(--primary);
    min-width: 250px;
    flex-grow: 1;
  }

  .cluster-additional-details {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: visible;
    padding: 24px 32px 24px 24px;

    .cluster-additional-details-inner {
      height: 100%;
      margin-right: 24px;
    }
  }

  .container-tag {
    border-radius: 20px;
    border: 1px solid var(--border);
    padding: 4px 16px;
    color: #575757;
  }
}


// grid components

.ecs-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}


// usage graph
.container-usage-container {
  //width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-usage-wrapper {
    width: 100%;
    height: 20px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;

    color: #ffffff;
    text-shadow: 1px 1px #000000;
  }

  .container-progress-text {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    z-index: 100;
  }

  .container-usage-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .usage-wrapper-cpu {
    background: rgba(255, 0, 0, 0.1);
  }

  .usage-wrapper-memory {
    background: rgba(0, 140, 255, 0.1);
  }

  .progress-cpu {
    width: 0;
    background: #ff4444;
  }

  .progress-memory {
    width: 0;
    background: #44abff;
  }
}


.cluster-status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.active-dot {
  height: 10px;
  width: 10px;
  border-radius: 30px;
  background: #37a800;
}

.cell-width-adjuster-class {
  width: 100%;

  > * {
    width: 100%;
  }

  span {
    width: 100%;
  }
}

.ecs-filter{
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr auto;
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;

  .ecs-filter-button-wrapper{
    margin-top: 8px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }
}