// ag-grid.scss defines structural styles and should be imported first
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";


.ag-theme-custom-react {
  @include ag-theme-alpine((
          foreground-color: #202124,
          secondary-foreground-color: var(--font-color-2),
          header-foreground-color: #70757a,
          background-color: rgb(255, 255, 255),
          odd-row-background-color: null,
          subheader-background-color: white,
          control-panel-background-color: white,
          header-background-color: ag-derived(background-color),
          row-hover-color: var(--table-row-hover),
          range-selection-border-color: rgba(255, 240, 0, 0),
          borders: false,
          borders-critical: true,
          borders-secondary: true,
          border-color: #d9d9d9,
          header-column-separator: true,
          input-border-color:#ffca13,
          row-height:40px,
          font-size: 12px,
          input-focus-box-shadow: null,
          checkbox-checked-color: ag-derived(input-border-color),
          input-focus-border-color: ag-derived(input-border-color),
          //font-family:('Century Gothic', Century Gothic),
          font-family:('Roboto', Century Gothic),
          popup-shadow: (0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.13))
  ));

  .ag-menu-option-active {
    background-color: #ffca13;
    color: white;
  }
}

// custom override styles by nithin

.ag-picker-field-wrapper {
  border-radius: 2px !important;
}

.ag-menu {
  border: solid 1px #00000012;
}

.ag-picker-field-icon {
  padding-right: 7px !important;
}

.data-grid-wrapper {
  text-align: start;
  box-sizing: border-box;
  height: 580px;
}

.grid-properties {
  width: fit-content;
  width: -moz-fit-content;
  max-width: 500px;

  .MuiPagination-ul {
    flex-wrap: nowrap;
  }
}

.ag-cell-data-change {
  background: red !important;
}

.ag-selection-checkbox {
  margin-right: 16px !important;
}

.ag-header-select-all {
  margin-right: 16px !important;
}

.ag-checked::after {
  color: var(--secondary) !important;
}

.ag-row-selected {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.data-grid-adjustments {
  text-align: left;
  padding: 0 8px 0 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);

  p {
    margin: 0;
    font-size: 10px;
  }

  .global-filter {
    outline: none;
    box-shadow: none;
    border: none;
    caret-color: #373737;
    width: 55%;
    font-size: var(--font-size);
    font-family: var(--font-family);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
    padding: 15px;
  }
}


.cell-editor {
  transition: 0.3s;

  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }

  .icon-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .edit-icon {
    opacity: 0;
    transition: 0.3s;
  }
}