.compute-optimizer {
  overflow: auto;
  height: calc(100vh - 280px);
}

.optimizer-filters{
  display: flex;
  flex-wrap: wrap;
  width:100%;
  gap: 20px;
  align-items: center;
}


