dashboard-wrapper{
  width: 100%;
  .dashboard-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 20px;
    width: 100%;
  }
}
