.rt-header {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  display: flex;
  gap: 16px;
  height: auto;
  padding-left: 0 !important;
  box-shadow: 0 -1px 4px 1px #d5d5d5;
}

.rt-standard-padding {
  padding: 0 20px;
}

.rt-organization-title {
  background: black;
  padding: 16px;
  padding-left: 24px;


  img {
    max-width: 200px;
  }
}


.rt-select-wrapper {
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;

  &:hover {
    background: #efefef;
  }
}


.rt-landing-wrapper {
  display: flex;

  .rt-grid-wrapper {
    flex: 1;
    border-right: 1px solid var(--border);
  }

  .rt-info-card {
    --info-background:rgba(248, 248, 248, 0.48);
    background: var(--info-background);
    border-radius: 10px;
    padding: 24px;
    border: 1px solid var(--border);

    ol {
      padding: 0 16px;
      margin-top: 8px;
      margin-bottom: 8px;

      li {
        padding: 2px 0;
      }
    }
  }

  .rt-additional-landing-info {
    padding: 16px;
    min-width: 250px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;


    .rt-use-full-links {
      display: grid;
      gap: 8px;
      padding-bottom: 32px;
    }
  }
}

.rt-pop-over-additional-info {
  padding: 24px;
  display: flex;
  gap: 24px;

  .rt-info-card {
    --info-background: rgba(248, 248, 248, 0.48);
    background: var(--info-background);
    border-radius: 4px;
    padding: 24px;
    border: 1px solid var(--border);
    height: fit-content;
    width: 50%;
  }

  .rt-content {
    background: transparent;
    border: none;

    .rt-content-copy{
      position: absolute;
      top: 0;
      right: 0;
    }
    .__json-pretty__ {
      padding: 0;
      font-size: 12px;
      margin: 0;
      line-height: 18px;
      font-family: 'Fira Mono', monospace;
    }

    .__json-key__ {
      color: rgb(255, 89, 89)
    }


    .__json-value__ {
      color: rgb(255, 0, 0);
      font-weight: 600;
      text-decoration: line-through;
    }


    .__json-string__ {
      color: #00ae1b
    }


    .__json-boolean__ {
      color: rgba(102, 153, 204, 1)
    }


    .__json-pretty-error__ {
      line-height: 1.3;
      color: rgba(248, 248, 242, 1);
      background: #1e1e1e;
      overflow: auto;
    }

  }

  code {
    line-height: 18px;
    font-size: 12px;
    font-family: 'Fira Mono', monospace;
  }


  .rt-application-data {
    transition: 0.3s;
    flex: 1;
    text-size-adjust: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .rt-raw-data {
    flex: 0;
    flex-basis: 400px;
    max-width: 100%;
    height: fit-content;
  }

  @media (max-width: 800px) {
    .rt-raw-data {
      flex: 1;
    }
  }

  .rt-use-full-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    flex: 1;
  }

  .rt-application-option {
    padding: 4px 16px 4px 8px;
  }
}