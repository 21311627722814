.preview-wrapper {
  position: fixed;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 96vh;
  height: calc(100vh - 99px);
  background: white;
  z-index: 100;
  margin: 20px;
  left: 0;
  top: 0;
  border-radius: 4px;
  box-shadow: 0 0 4px 2px #cacaca;
  padding: 30px;
  overflow: auto;

  a {
    color: white;
    text-decoration: none;
  }

  .report-preview-title {
    padding: 20px 0;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
  }

  .report-preview-view {
    .table-container {
      width: 100%;
      height: calc(100vh - 212px);
    }
  }
}


div[role=tooltip] {
  z-index: 100;
}

.preview-title {
  display: flex;
  width: 100%;
  margin-right: 20px;

  .title-subheadings {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
}

.insights-wrapper {
  width: 100%;

  p, ul, li {
    margin: 0;
    padding: 0;
  }

  .insights-daily-cost-wrapper {
    display: flex;
    justify-content: right;
    align-items: center;
    align-content: center;
    height: 100%;
    color: #414141;
    background: linear-gradient(to right, #fff 20%, #ffd947);


    .insight-title {
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 400;
      font-size: 24px;
      border-right: 1px solid #00000040;
    }


    ul {
      list-style: decimal;
      padding-top: 0px !important;

      li {
        margin: 10px 10px 10px 20px;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      padding-top: 10px;
    }

    .content {
      margin: 10px 10px 10px 0;
      font-weight: 500;
      padding-top: 0;
      font-size: 20px;
    }

    .item {
      border-right: 1px solid #545454;
      padding: 0 30px;
    }
  }
}

.d-n {
  display: none;
}