$base_border_color : rgba(0, 0, 0, 0.1);
$base_background_color: rgba(0, 0, 0, 0.04);
$base_border_color:rgba(0, 0, 0, 0.12);
$drawer_font_color: rgba(0, 0, 0 , 0.65);
$primary_font_color: rgba(0, 0 ,0 , .95);
$secondary_font_color: $drawer_font_color;
$main_headings_color: $drawer_font_color;

// font size

$main_headings: 20px;

//font weight
$main_heading_weight: 500;
