@import '../../assets/variables';


.recommendation-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 30px auto auto;
  width: 90%;

  .span-header{
    font-size: 28px;
    width: 100%;
  }

  .sub-budget-heading{
    margin: 0;
    padding: 10px;
    padding-left: 20px;
    padding-top: 15px;
    width: 100%;
  }

  .recommendation-container{
    padding: 20px 0;
    width: 100%;

    b{
      font-weight: 500;
      color: #5a5a5a
    }

    a{
      text-decoration: none;
    }

    .d-n{
      display: none;
    }
    .recommendations{
      padding-top: 20px;
    }

    .spinner-wrapper{
      display: flex;
      padding-left: 10px;
      flex-direction: row;
      align-items: center;

      span{
        padding-left: 10px;
      }
    }
  }
}

.c-r-o{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 40px;

  .card{
    padding: 20px;
    color: $main_headings_color;
    position: relative;

    p{
      font-size: 14px;
      margin: 5px 0 0;
      font-weight: 500;
    }

    .summary{
      margin-top: 10px;
      width: 100%;
      border-top: 1px solid $base_border_color;
      padding-top: 10px;
      margin-bottom: 20px;

      .title{
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .s-r{
        font-size: 14px;
        font-weight: 400;
        display: flex;
      }

    }
  }

  .more-info{
    cursor: pointer;
    border-top: 1px solid $base_border_color;
    bottom: 0;
    padding-top: 20px;
    padding-left: 2px;
    font-weight: 500;
    color: blue;
    width: 100%;
    display: block;

    &:hover{
      text-decoration: underline;
    }
    &:visited{
      color: blue;
    }
  }
}