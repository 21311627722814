.notification-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  width: -webkit-fill-available;
  width: -moz-available;
  z-index: 1;
  justify-content: space-between;
  background: white;
  //box-shadow: 0 0 13px 4px #00000036 !important;
  display: flex;
  align-items: center;
  padding:  16px 32px;
}