.report-list{
  margin-top: 11px;
  height: calc(100vh - 350px);
  overflow: auto;
  padding-right: 7px;

  .adhoc-class{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    p{
      margin: 0;
      font-size: 18px;
      padding: 20px;
    }
  }
}