$primary : #535353;
$button-text : #989898;
$border: #c8c8c8;

.adhoc-report{
  border-radius: 5px;
  border: 1px solid #c8c8c8;
  margin-top: 40px;
  min-height: 410px;
  padding: 20px;

  .sub-wrapper{
    height: 40px;
  }

  .adhoc-title{
    display: block;

    h2{
      font-size: 20px;
      margin: 10px 0;
      color: $primary;
    }
    p{
      margin: 0;
    }
  }
  .query-filter{
    margin-top: 20px;
    display: flex;

    p{
      margin: 0;
      margin-left: 2px;
      font-size: 12px;
      color: $button-text;
      font-weight: 600;
    }

    .query-preview{
      border: 1px solid $border;
      margin: 0;
      margin-right: 20px;
      width: 25%;
      height: 272px;
      border-radius: 7px;
      padding: 20px;
      max-width: 250px;
    }
    .filter-title{
      margin: 10px 5px;
    }
  }

  .filter-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filter-box{
    max-height: 145px;
    overflow: auto;
  }

}