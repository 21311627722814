/*//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
@import url('http://fonts.cdnfonts.com/css/century-gothic');*/
@import "assets/variables";

@font-face {
  font-family: Century Gothic;
  src: url('./assets/fonts/gothic_regular.ttf');
}

/*@font-face {
  font-family: 'Century Gothic';
  src: url('./assets/fonts/gothic_bold.ttf');
  font-weight: 900;
  font-style: normal;
}*/


:root {
  --fnt-title-size: 20px;
  --fnt-title-weignt: 500;
  --fnt-subtitle-size: 18px;
  --fnt-title-clr1: 19px;
  --fnt-subtitle-clr1: 18px;
  --font-familly: 'Roboto', sans-serif;
  --primary: #1ea433;
  --secondary: #000;
  --border: #e1e1e1;
  --border-radius: 4px;
  --secondary-background: #fcfcfc;
  --light: #fff;
  --light-font: #000000c4;
  --dark: rgba(0, 0, 0, 0.69);
  --dark-font: #fff;
  --fnt-wt-drawer-item: 600;
  --secondary-highlight: #b6b6b6;
  --fd-font-color-1: #000;
  --fd-background-1: #ffffff;
  --fd-background-2: #f6f6f6;
  --fd-background-3: #c2c2c2;
  --fd-background-4: #8f8f8f;
  --fd-background-5: #808080;
  --fd-background-6: #595959;
  --fd-background-8: #2d2d2d;
  --fd-font-color-10: #fff;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-familly);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  scroll-behavior: smooth;
}


.app-wrapper {
  width: 100%;

  .app-container {
    padding-top: 70px;
    display: flex;
  }

  .dashboard-main-wrapper {
    /*padding-left: 251px;*/
    width: 100%;
  }
}

.spinner-wrapper {
  display: flex;
  //padding-left: 10px;
  flex-direction: row;
  align-items: center;

  span {
    padding-left: 10px;
  }
}


.a-i-c {
  display: flex;
  align-items: center;
  gap: 10px
}

.d-f-a-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .header-sidenav {
    display: none;
  }
}

.span-header {
  font-size: $main_headings !important;
  font-weight: $main_heading_weight !important;
}


.MuiPaper-elevation8 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.13) !important;
}


.container {

}

.d-f {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.d-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}

.j-c-c {
  justify-content: start;
}


.page-title-wrapper{
  margin: 24px 65px 36px 40px;
}
.title-div-wrapper {
  margin: 30px 65px 50px 40px;
}

.__json-pretty__ {
  padding: 20px;
  border-radius: 4px;
}


.custom-date-calender {
  align-items: start;
  background-color: #eff2f7;
  border-radius: var(--border-radius);
  border: 1px solid var(--border);
  //box-shadow: 0 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .icon-button {
    margin: 8px;
  }
}

