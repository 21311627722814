@import "../../../assets/variables";

.insight-reports {
  margin-left: 70px;
  margin-top: 30px;

  .insight-report-wrapper {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  --flex-values: 1 1 30%;

  .custom-wu-report-card-second {
    --flex-values: 1 1 100% !important;
  }

  .custom-wu-report-card {
    width: fit-content;
    flex: var(--flex-values);

    .custom-wu-report-card-title {
      font-size: 14px;
    }

    .custom-wu-report-card-pos {
      margin-top: 20px;
    }

    .custom-wu-report-card-content {
      padding-bottom: 8px;
    }

    .custom-wu-report-card-title {
      display: inline-block;
      margin: 0 2px;
      transform: scale(0.8);
    }
  }

  .span-header {
    padding-bottom: 20px;
  }
}

.wu-report-insight {
  margin-top: 30px;
  border-top: 1px solid $base_border_color;
  padding-top: 30px;

  .report-summary-summary {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .report-summary-table-wrapper {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .datagrid-class {
    width: 100%;
    border-radius: 4px;
  }

  .table-heading {
    font-weight: 600;
    font-size: 16px;
    color: $drawer_font_color;
    margin: 0 0 20px;
  }

  .summary-card {
    padding: 10px;
    width: 100%;

    .title {
      font-weight: 500;
      margin: 0;
      font-size: 14px;
      padding-bottom: 10px;
      color: $drawer_font_color;
      border-bottom: 1px solid $base_border_color;
    }

    .content {
      font-weight: 500;
      color: $drawer_font_color;
      padding-top: 5px;
      margin: 0;
      font-size: 28px;
    }
  }

  .detail-report {
    display: flex;
    align-items: center;
    gap: 50px;
  }
}