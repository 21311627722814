.account-warning-1 {
  // more than 100
  background-color: green;
  color: #fff;
}

.account-warning-2 {
  // more than 80
  background-color: #04a900;
  color: #fff;
}

.account-warning-3 {
  // more than 60
  background-color: #0ac70a;
}

.account-warning-4 {
  // more than 40
  background-color: #00d900;
}

.account-warning-5 {
  // more than 20
  background-color: #8de700;
}

.account-warning-6 {
  // more than 0
  background-color: #aaef00;
}

.account-warning-7 {
  // more than +20
  background-color: #edff00;
}

.account-warning-8 {
  // more than +40
  background-color: #e8b200;
  color: white;
}

.account-warning-9 {
  // more than +60
  background-color: #f86631;
  color: #fff;
}

.account-warning-10 {
  // more than +80
  background-color: #f84231;
  color: #fff;
}


.legend-box-account-trend{
  height: 12px;
  width: 12px;
  //background-color: red;
  border-radius: 4px;
}

.active-filter{
  background-color: red;
}

.legend-item{
  border-radius: 4px;
  transition: 0.3s;
}