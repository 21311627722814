.guard-rails-item {
	width: 100%;

	.title {
		font-size: 20px;
		font-weight: 400;
		padding: 10px 10px;
	}

	.accord-details {
		background: #ffe00033;
		padding: 15px 20px;
		font-weight: 500;
		color: #484848;
		display: flex;
	}

	.inner-wrapper {
		padding: 10px 10px;
		overflow: auto;
		height: calc(100vh - 335px);
	}

	.summary {
		padding-left: 20px;
		color: #2626ff;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.tag-policies {
		padding: 5px 0 20px;

		.policy-item {
			padding-left: 20px;
			padding-bottom: 10px;
			padding-right: 20px;
			border-bottom: 1px solid #dedede;

			p {
				font-weight: 500;
				margin: 10px 0;
				color: #5b5b5b;
			}

			span {
				font-size: 14px;
			}
		}
	}
}

.item-list {
	padding: 5px 0;

	span {
		width: 200px;
		display: inline-table;
		font-weight: 500;
	}
}

.policy-preview {
	margin-top: 15px;
	padding-top: 15px;
	.__json-pretty__ {
		padding: 20px;
	}

	.tag-value {
		display: flex;

		p{
			margin: 0;
			margin-top: 5px;
			margin-bottom: 20px;
			font-weight: 400;
		}
	}

	ul{
		margin: 0;
		list-style: decimal;
		padding: 0 15px;

		li{
			padding: 5px 0;
			padding-left: 10px;
		}
	}

}
