@import "../../assets/variables";

.ob-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px auto auto;

  .span-header {
    font-size: $main_headings;
    font-weight: $main_heading_weight;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .sub-title {
      margin: 0;
      font-size: 18px;
      padding-top: 20px;
    }
  }


  /*m-d -> migration dashboard*/
  .m-d-w {
    width: 100%;
    padding-top: 25px;
    margin: auto;
  }
}

.m-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ob-table-wrapper {
  height: calc(100vh - 262px);

  .table:hover {
    background: #ffe00026;
  }
}

.search {
  position: fixed;
  top: 90px;
  right: 4.3%;
}

.table {
  color: red;
  cursor: pointer;
}

.auto-complete-account {
  width: 540px;
}

.application-headings {
  font-weight: 500 !important;
  color: #626262;
}

.onboarding-grid-wrapper {
  border: 1px solid var(--border);
  border-radius: 2px;

  .onboarding-grid {
    height: calc(100vh - 270px);
  }
}


.onboard-form {
  display: grid;
}

.account-environment {
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  margin-top: 10px;
  padding: 10px 20px;
}

.add-button{
  border: 1px solid var(--border);
}

@media only screen and (min-width: 1201px) {
  .auto-complete-account {
    width: 610px;
  }
}
