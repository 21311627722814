@import "../../assets/variables";

.landing-container {
  width: 96%;
  margin: 16px;

  .title {
    font-size: 28px;
    margin: auto;
    width: 98%;
  }

  .chart-container {
    width: 100%;
    padding-top: 0;
    display: flex;

    .chart-left {
      margin: auto;
      width: 47%;
      float: left;
    }

    .chart-right {
      margin: auto;
      width: 47%;
      float: right;
    }

    .sub-heading {
      font-size: 20px;
      font-weight: 500;
    }

    .paper-subtitle {
      margin: 20px 0;
      line-height: 1.5rem;
      min-height: 50px;
    }
  }

  .paper-cost {
    margin: 20px auto;
    padding: 10px;
    width: 96%;
    transition: 0.3s;
    background: linear-gradient(to right, #00000000 20%, #ffd500);

    .master-spend {
      font-weight: 500;

      p {
        color: #565656;
        font-size: 18px;
        margin: 10px 0 0;
      }

      h2 {
        color: #565656;
        margin: 10px 0;
        font-size: 24px;
      }
    }

    .cost-wrapper {
      display: flex;
      justify-content: space-between;

      .cost-container {
        span {
          border-left: 1px solid $base_border_color;
          padding-left: 20px;
          padding-right: 30px;
          width: -webkit-fill-available;
          width: -moz-available;
          min-width: 165px;
          margin: 10px 0;

          p {
            margin: 0;
            font-size: 16px;
            margin-top: 10px;
          }

          h2 {
            font-weight: 400;
            font-size: 24px;
            margin: 10px 0;
          }
        }
      }

      span {
        width: 20%;
        margin: auto 0;
      }
    }
  }
}

.chart-class {
  width: 100%;
  height: 300px;
}

.main-wrapper {
  background-color: #ec9351;
}

// new styles
.w-100 {
  width: -webkit-fill-available;
  width: -moz-available;
}

.wrapper-style {
  display: flex;
  margin: 32px 16px 16px;
}

.total-spend-card-wrapper {
  background-color: rgba(0, 0, 0, 0.03) !important;
  position: relative;
  //box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.06);
  //border: 1px solid var(--border);

  .graph-spend-summary {
    position: absolute;
    z-index: 0;
    width: calc(100% - 26px);
    left: 20px;
    margin-top: -35px;
  }

  .mask {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to right, #fff 35%, #90404000);
  }

  .total-spend-card-content {
    margin: 16px;
    padding: 24px;
    border-radius: var(--border-radius);
    background-color: rgb(255, 255, 255);
  }

  .single-account-spend {
    margin: 16px;
    padding: 10px 24px;
    background-color: rgb(255, 255, 255);
    border-radius: var(--border-radius);
  }
}

.gap {
  gap: 20px;
}


