@import "../../assets/variables";

.sidebar-wrapper {
  left: 0;
  width: 250px;
  transform: translateX(0px);
  transition: 0.3s;
  position: fixed;
  height: 100vh;
  background: var(--secondary-background);
  border-right: 1px solid $base_border_color;

  ul {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 15px 0 0;

    li {
      a {
        text-decoration: none;
        padding: 10px 10px 10px 10px;
        margin: 0 10px;
        color: $drawer_font_color;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 14px;
        transition: 0.3s;

        &:hover {
          //border-radius: 0 30px 30px 0;
          background: $base_background_color;
          transition: 0.3s;
        }
      }
    }
  }
}

.active {
  //border-radius: 0 30px 30px 0;
  background: $base_background_color;
  color: #1ea433 !important;
  transition: 0.3s;
  border-radius: var(--border-radius);
}

.sidebar-translation-close {
  transform: translateX(-301px);
  transition: 0.3s;
  width: 100px;
}

.about {
  bottom: 70px;
  position: fixed;
  z-index: 100;
  width: 250px;
}

.expandable-nav-bar {
  border: none;
  outline: none;
  box-shadow: none !important;
  display: block;
  text-decoration: none;
  padding-left: 8px;
  color: $drawer_font_color !important;
  font-weight: 600;
  align-items: center;
  font-size: 14px;
  background-color: var(--secondary-background) !important;
  transition: 0.3s;

  .active {
    border-left: none;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), $base_background_color);
  }

  &:before {
    background-color: #fff !important;
  }

  a {
    text-decoration: none;
    padding: 10px 10px 11px 10px;

    margin: 0 10px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: 0.3s;
  }
}