.user-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;

  .user-login-wrapper {
    padding: 48px 32px;
    width: 400px;
    height: 500px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgba(100, 100, 100, 0.36) 0 0 10px;
    box-sizing: border-box;
    margin: auto;
    text-align: center;
  }

  .user-container {
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    display: flex;
  }
}
