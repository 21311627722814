.automation-container {
  width: 90%;
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .automation-wrapper {
    width: 100%;

    .span-header {
      font-size: 28px;
    }

    .select-buttons {
      margin-top: 20px;
    }
  }

  .automation-tasks {
    width: 100%;
    margin-top: 15px;
  }

}

.w-100 {
  width: -webkit-fill-available;
  width: -moz-available;
}

.gap {
  gap: 16px;
}

.automation-wrapper-main {
  width: 100%;
  padding-top: 20px;
}

.power-schedule {
  display: flex;

  .input-params {
    padding: 20px;
    width: 50%;
  }
}


.power-schedule-summary-card {
  background-color: rgba(239, 239, 239, 0.76) !important;
  margin: 16px 16px 24px;
  padding: 16px;
  position: relative;
}

.ps-graph-wrapper {
  position: relative;
  height: 40px;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;

  .ps-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: var(--border-radius);
  }
}

.custom-graph-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-help-text {
  position: absolute;
  right: 8px;
  top: 8px;
  color: var(--secondary-highlight);
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

.power-scheduled-wrapper {
  position: fixed;
  bottom: 0;
  right: 24px;
  transition: 1s;
  z-index: 1001;
}

.power-schedule-download {
  padding: 24px;
  width: 300px;
  position: relative;
  margin-bottom: -54px;
  border-radius: 24px !important;
  border: 1px solid var(--border) !important;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 1000;
  }
}

.error-ps {
  border: 2px solid red !important;
}

.power-schedule-small-select {
  height: 40px;
  border-radius: var(--border-radius);
  outline: none;
  border: 1px solid var(--border);
}