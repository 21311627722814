.custom-tab {
  margin-top: 10px;
  --accent-color: #1ea433;

  ul {
    display: flex;
    list-style: none;
    gap: 40px;
    border-bottom: 1px solid #0000001c;
    padding-bottom: 14px;

    li {

      a {
        color: inherit !important;
        padding: 16px 0;
        text-decoration: none;
        font-weight: 500;
      }


      .active {
        color: var(--accent-color) !important;
        text-decoration: none;
        font-weight: 500;
        background: rgba(0, 0, 0, 0);
        transition: 0.3s;
        position: relative;
        &::after{
          position: absolute;
          height: 5px;
          left: 0;
          bottom: 0;
          background: var(--accent-color);
          content: "";
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          width: 100%;
        }
      }
    }
  }
}